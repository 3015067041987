import classNames from 'classnames';
import { Button } from '../index';
import styles from './styles.module.scss';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap'

const url = 'https://gmail.us18.list-manage.com/subscribe/post?u=857c4b939a8d07d226212bb3f&amp;id=df1a6f1e27&amp;f_id=001510e7f0'

type FormProps = {
	status: string | Error | null;
	message: string | Error | null;
	onValidated: (formData: { EMAIL: string }) => void;
};

const CustomForm = ({ status, message, onValidated }: FormProps) => {
	const [validationError, setValidationError] = useState(false);
	const [email, setEmail] = useState('');
	const submit = () => {
		validateEmail(email);

		if (!validationError && email) {
			onValidated({
				EMAIL: email,
			});
		}

	}


	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/;
		setValidationError(!re.test(email));
	}

	return (
		<div className={styles.formWrapper}>
			<input
				className={styles.input}
				onChange={(e) => {
					setEmail(e.target.value);
					setValidationError(false);
				}}
				onBlur={() => validateEmail(email)}
				onFocus={() => setValidationError(false)}
				type="email"
				placeholder="Email-ul tău"
			/>
			{
				message && <div
					className={classNames({
						[styles.validate]: true,
						[styles.error]: status === "error",
						[styles.success]: status === "success",
					})}
				>{message as string}</div>
			}
			{
				validationError && <div className={styles.validationError}>Email-ul nu este valid</div>
			}
			<Button loading={status === "sending"} onClick={submit} label='Abonează-te acum' />
		</div>
	);
};



const NewsLetter = () => {
	return (
		<div className={styles.newsLetterWrapper} id="newsLetterWrapper">
			<Controller>
				<Scene
					triggerElement={"#newsLetterWrapper"}
					duration={'600'}
				>
					{(progress: number) => (
						<Tween
							from={{
								top: '100%',
								opacity: 0,
								scale: '0.7'
							}}
							to={{
								top: 0,
								opacity: 1,
								scale: 1
							}}
							ease="Strong.fadeInOut"
							totalProgress={progress}
							paused
						>
							<div className={styles.textWrapper}>
								<p className={styles.textOne}>Vrei să fii tânăr adecvat?</p>
								<p className={styles.textTwo}>ABONEAZĂ-TE</p>
								<p className={styles.textTwo}>LA NEWSLETTER</p>
								<p className={styles.textThree}>
									Abonează-te la newsletter-ul nostru pentru a fi primul care află când are loc următoarea Maneliada. Cine
									știe, poate primești și un voucher
								</p>
								<MailchimpSubscribe
									url={url}
									render={({ subscribe, status, message }) => (
										<CustomForm
											status={status}
											message={message}
											onValidated={(formData: { EMAIL: string }) => subscribe(formData)}
										/>
									)}
								/>
							</div>
						</Tween>
					)}
				</Scene>
			</Controller>
		</div>
	);
};

export default NewsLetter;
