import styles from './styles.module.scss'
import insta from '../../assets/icons/instagram.svg'
import fb from '../../assets/icons/f.svg'
import tik from '../../assets/icons/tiktok.svg'
import yt from '../../assets/icons/yt.svg'
import logo from '../../assets/logo.webp'

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.upperwrapper}>
                <a href='#home'><img src={logo} alt="logo" height={"4rem"} /></a>
                <div className={styles.menu}>
                    <a href='#home' target='_blank' rel='noreferrer noopener' >
                        <p>Acasă</p>
                    </a>
                    <a id="bilete" href='https://bilete.maneliada.ro/' target='_blank' rel='noreferrer noopener' >
                        <p>Bilete</p>
                    </a>
                    <a href='mailto:contact@maneliada.ro' target='_blank' rel='noreferrer noopener' >
                        <p>Contact</p>
                    </a>
                </div>
                <div className={styles.socialWrapper}>
                    <a href='https://facebook.com/maneliada' target='_blank' rel='noreferrer noopener' >
                        <img src={fb} alt="facebook" width="30px" />
                    </a>
                    <a href='https://instagram.com/maneliada' target='_blank' rel='noreferrer noopener' >
                        <img src={insta} alt="instagram" width="30px" />
                    </a>
                    <a href='https://tiktok.com/@maneliada_ofc' target='_blank' rel='noreferrer noopener' >
                        <img src={tik} alt="tiktok" width="30px" />
                    </a>
                    <a href='https://www.youtube.com/@maneliadatv' target='_blank' rel='noreferrer noopener' >
                        <img src={yt} alt="youtube" width="30px" />
                    </a>
                </div>

            </div>
            <div className={styles.line} />
            <div className={styles.lowerWrapper}>
                <p className={styles.copyright}>© 2021 - {new Date().getFullYear()} MANELIADA</p>
                <div className={styles.menu}>
                    <a href='https://maneliada-termeni.s3.eu-west-1.amazonaws.com/politica-de-confidentialitate.pdf' target='_blank' rel='noreferrer noopener' >
                        <p>POLITICĂ DE CONFIDENȚIALITATE</p>
                    </a>
                    <div className={styles.verticalLine} />
                    <a href='https://maneliada-termeni.s3.eu-west-1.amazonaws.com/termeni-si-conditii-Maneliada-2024.pdf' target='_blank' rel='noreferrer noopener' >
                        <p>TERMENI ȘI CONDIȚII</p>
                    </a>
                </div>
            </div>

        </div>
    )
}

export default Footer
