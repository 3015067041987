import { useLottie, useLottieInteractivity } from "lottie-react";
import likeButton from "../../assets/data.json";

const options = {
    animationData: likeButton,
};

const Example = ({ isMobile }: { isMobile: boolean }) => {
    const lottieObj = useLottie(options, {
        height: `${(isMobile ? 400 : window.innerHeight) + 200}px`
    });
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.1],
                type: "stop",
                frames: [0],
            },
            {
                visibility: [0.1, 0.9],
                type: "seek",
                frames: [0, 100],
            },
            {
                visibility: [0.9, 1.0],
                type: "stop",
                frames: [100],
            },
        ],
    });

    return Animation;
};

export default Example;
