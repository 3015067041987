import styles from './styles.module.scss';

type Props = {
    inline?: boolean;
}

const Loader = (props: Props) => {
    const { inline } = props;
    if (inline) {
        return (
            <div className={styles.inlineWrapper} />
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.ldsGrid}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loader;
