import styles from './styles.module.scss'
import p16000 from '../../assets/images/1-1600.webp';
interface Props {
    video: string;
    id: string
}
const Video = (props: Props) => {
    const { video, id } = props;

    return (
        <div id={id} className={styles.videoWrapper}>
            <video
                preload='metadata'
                height={'100%'}
                playsInline
                muted
                autoPlay
                loop
                poster={p16000}
            >
                <source src={video} type="video/mp4" />
            </video>
        </div >
    )
}

export default Video;
