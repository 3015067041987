import styles from './styles.module.scss'
import p16000 from '../../assets/images/1-1600.webp';
interface Props {
    video: string;
    id: string;
    setLoading: (isLoading: boolean) => void;
}
const Video = (props: Props) => {
    const { video, id, setLoading } = props;

    return (
        <div id={id} className={styles.videoWrapper}>
            <video
                preload='metadata'
                onCanPlay={() => { setLoading(false); }}
                height={'100%'}
                playsInline
                muted
                autoPlay
                loop
                poster={p16000}
            >
                <source src={video} type="video/mp4" />
            </video>
        </div>
    )
}

export default Video;
