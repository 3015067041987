import styles from './styles.module.scss'

interface Props {
    children: React.ReactNode;
    color?: string;
    isHomePage?: boolean
}

const Layout = (props: Props) => {
    const { children, isHomePage } = props;
    return (
        <div className={styles.layout} style={{ ...isHomePage && { margin: 0 } }}>
            {children}
        </div>
    )
}

export default Layout;
