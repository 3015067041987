import classNames from 'classnames'
import { CSSProperties } from 'react'
import styles from './styles.module.scss'

interface Props {
    classNames?: CSSProperties
    icon: string
    title: string
    subtitle: string
    reverse?: boolean
    progress?: number
}

const InfoBox = (props: Props) => {
    const { icon, title, subtitle, reverse } = props
    return (
        <div
            className={classNames({
                [styles.wrapper]: true,
                [styles.reverse]: reverse,
            })}>
            <div className={styles.textWrapper}>
                <p className={styles.title}>{title}</p>
                <p className={styles.subTitle}>{subtitle}</p>
            </div>
            <div className={styles.iconWrapper}><img width='30px' src={icon} alt="icon" /></div>
        </div>
    )
}

export default InfoBox