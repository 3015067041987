import classNames from 'classnames'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap'

interface Props {
    id: string
    image?: string
    srcset?: string
    text?: string
    author?: string
    portrait?: boolean
    reverse?: boolean
    direction?: 'top' | 'right' | 'bottom' | 'left'
}

const Image = (props: Props) => {
    const { id, image, text, author, portrait, reverse } = props
    const [isMobile, setIsMobile] = useState(false)


    useEffect(() => {
        setIsMobile(/Android|iPhone/i.test(navigator.userAgent));
    }, [])
    return (
        <div
            id={id}
            className={classNames({
                [styles.wrapper]: true,
                [styles.portrait]: portrait && !isMobile,
                [styles.reverse]: reverse && !isMobile
            })}>
            <Controller>
                <Scene
                    triggerElement={`#${id}`}
                    duration={'400'}
                >
                    {(progress: number) => (
                        <Tween
                            from={{
                                top: '100%',
                                opacity: 0,
                                scale: '0.7'
                            }}
                            to={{
                                top: 0,
                                opacity: 1,
                                scale: 1
                            }}
                            ease="Strong.fadeInOut"
                            totalProgress={progress}
                            paused
                        >
                            <div
                                className={classNames({
                                    [styles.imageWrapper]: true,
                                    [styles.portrait]: portrait && !isMobile,
                                    [styles.reverse]: reverse && !isMobile
                                })}
                                style={{ background: `url(${image}) no-repeat center center/cover` }}
                            />
                            <div className={styles.textWrapper}>
                                <p>{text}</p>
                                <p> - {author}</p>
                            </div>
                        </Tween>
                    )}
                </Scene>
            </Controller>
        </div>
    )
}

export default Image