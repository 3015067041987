import styles from './styles.module.scss';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap'

interface Props {
	text: string;
}
const TextEffect = (props: Props) => {
	const { text } = props;
	return (
		<div id='textWrapper' className={styles.textEffectWrapper}>
			<Controller>
				<Scene
					triggerElement="#textWrapper"
					duration={'1500'}
				>
					{(progress: number) => (
						<Tween
							from={{
								opacity: 1,
								top: '-50%'
							}}
							to={{
								opacity: 1,
								top: '35%'
							}}
							ease="Strong.easeOut"
							totalProgress={progress}
							paused
							className={styles.gradient}
						>
							<div className={styles.gradient} />
						</Tween>
					)}
				</Scene>
			</Controller>
			<p id="testgradient">{text}</p>
		</div>
	);
};

export default TextEffect;
