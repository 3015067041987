import { Loader } from '..'
import styles from './styles.module.scss'

interface Props {
  label: string
  isUrl?: boolean
  url?: string
  id?: string
  customStyles?: unknown
  type?: "submit" | "reset" | "button" | undefined
  onClick?: () => void
  loading?: boolean
}

const Button = (props: Props) => {
  const { id, onClick, label, isUrl, url, customStyles, type, loading } = props

  if (isUrl) {
    return (
      <a id={id} style={customStyles || {}} className={styles.buttonWrapper} href={url} target="_blank" rel="noopener noreferrer">{label}</a>
    )
  }
  return (
    <button onClick={onClick} type={type || 'button'} id={id} className={styles.buttonWrapper} style={customStyles || {}}>
      {loading ? <Loader inline /> : label}
    </button>
  )
}

export default Button
